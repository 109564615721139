<template>
  <mainpage msg="Welcome to Payment Master App"/>
</template>

<script>
// @ is an alias to /src
import mainpage from '@/components/mainpage.vue'

export default {
  name: 'Home',
  components: {
    mainpage
  }  
}
</script>
