import store from '.././store'
import paymentMasterContractSource from '../.././build/contracts/PaymentMaster.json'

const paymentMasterContract = "0xbbf3c5d707f7f06788c9c4b7d09a234115da63f0";

const Web3 = require("web3");

const runMetamask = () => {
    try {
        //Will Start the MetaMask Extension
        window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
        console.error(error);
    }
}

const GetWeb3 = new Promise(function (resolve) {
    store.dispatch('writepaymentMasterContractAddress', paymentMasterContract);
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        window.ethereum.enable();
        window.ethereum.on('accountsChanged', handleAccountsChanged);        
        runMetamask();
        startEventListener(window.web3)
        return resolve([true, window.ethereum, window.web3]);
    }
    return resolve([false, null, null]);
})

function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      runMetamask()      
    } else if (accounts[0] !== store.state.mainAddress) {      
      store.dispatch('registerWeb3')
    }
}

function startEventListener(web) {
    // Award watch events     
    let myContract = new web.eth.Contract(paymentMasterContractSource.abi, paymentMasterContract)
    myContract.events.Win({fromBlock: 0}, (err, element) => {
            if(err) {
                console.log('Error while subscribe Win events!', err)
            }

            if(element) {
                store.dispatch('logWin', {
                    WinHash:    element.transactionHash,
                    gameNumber:     element.returnValues.gameNumber,
                    stakeNumber:    element.returnValues.stakeNumber,
                    winValue:   element.returnValues.winValue
                })
            }
        }
    );
    myContract.events.Shoot({fromBlock: 0}, (err, element) => {
            if(err) {
                console.log('Error while subscribe Win events!', err)
            }

            if(element) {
                store.dispatch('logShoot', {
                    Shoothash:      element.transactionHash, 
                    wallet:         element.returnValues.wallet,
                    gameNumber:     element.returnValues.gameNumber, 
                    stakeNumber:    element.returnValues.stakeNumber, 
                    ShootValue:     element.returnValues.ShootValue
                })
            }
        }
    );
}

const writeWins = () => {
    store.dispatch('writeWinsToHistory', {})
}

export { GetWeb3, runMetamask, writeWins }