<template>
  <div class="content contMain">
    <div class="content contTop">
      <div class="stakeBlock">
        <div class="stakeBlockSubdetail stake">
          <div class="stakeBlockSubdetailRow head">Your wallet balance:</div>
          <div class="stakeBlockSubdetailRow">{{ ethBallanceNice }}</div>
        </div>
        <div v-if="!Web3.connected">
          <button :disabled="Web3.connected" v-on:click="connectMetamask()">Connect metamask!</button>
        </div>
        <div v-else-if="Web3.connected">
          <button :disabled="!Web3.connected" v-on:click="makeStake()">Make a Bet</button>
        </div>
      </div>
    </div>
    <div class="content contBottom">
      <div class="hist">
        <div class="hist__detail top">
          <div class="hist__subdetail"><div class="hist__head">Wallet</div></div>
          <div class="hist__subdetail"><div class="hist__head">Game #</div></div>
          <div class="hist__subdetail"><div class="hist__head">Shoot #</div></div>
          <div class="hist__subdetail"><div class="hist__head">Stake</div></div>
          <div class="hist__subdetail"><div class="hist__head">Win</div></div>
        </div>
        <div class="hist__detail" v-for="award in history" :key="award.Shoothash">
          <div class="hist__subdetail">
            <div class="hist__val">{{ award.walletNice }}</div>
          </div>
          <div class="hist__subdetail">
            <div class="hist__val">{{ award.gameNumber }}</div>
          </div>
          <div class="hist__subdetail">
            <div class="hist__val">{{ award.stakeNumber }}</div>
          </div>
          <div class="hist__subdetail">
            <div class="hist__val linkTo" v-on:click="runHashInfo(award.Shoothash)">{{ award.ShootValue }}</div>
          </div>
          <div class="hist__subdetail">
            <div class="hist__val linkTo" v-on:click="runHashInfo(award.WinHash)">{{ award.winValue }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { runMetamask } from "../util/getWeb3.js";
import ContractABI from "../.././build/contracts/PaymentMaster.json";

export default {
  name: "mainpage",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch("registerWeb3");
  },
  computed: mapState({
    Web3: "Web3",
    mainAddressShort: "mainAddressShort",
    mainAddress: "mainAddress",
    connected: "connected",
    ethBallance: "ethBallance",
    ethBallanceNice: "ethBallanceNice",
    ContractAddress: "gameContractAddress",
    history: "stakesHistory",
  }),
  methods: {
    connectMetamask() {
      if (window.ethereum) {
        runMetamask();
        this.$store.dispatch("registerWeb3");
      }
    },
    runHashInfo(_hash) {
      window.open("https://rinkeby.etherscan.io/tx/" + _hash, "_blank");
    },
    makeStake() {
      let myContract = new this.Web3.instance.eth.Contract(ContractABI.abi, this.ContractAddress);
      myContract.methods.getPayment().send({ from: this.mainAddress, value: "100000000000000000", gas: "500000" }, (error, transactionHash) => {
        console.log(error, transactionHash);
      });
    },
  },
};
</script>
