let state = {
    mainAddress:"unknown",
    mainAddressShort:"unknown",
    ethBallance: 0,
    ethBallanceNice: 0,
    gameContractAddress: '0x0',
    Web3: {
        instance: null,
        connected: false
    },
    stakesHistory: [ /* {Shoothash: 'Shoothash', wallet: 'wallet', walletNice: 'walletNice', gameNumber: 'gameNumber', stakeNumber: 'stakeNumber', ShootValue: 'ShootValue', WinHash: 'WinHash', winValue: 'winValue'} */ ],
    winHistory: [ /*WinHash, gameNumber, stakeNumber, winValue*/]
};

export default state;