import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import { GetWeb3, writeWins } from './../util/getWeb3.js'

var HISTORY_GAMES_COUNT = 5; // show history game rounds including incomplete last round

Vue.use(Vuex)

export default new Vuex.Store({
    state: state,
    mutations: {
        async registerWeb3Instance (state, payload) {            
            state.Web3.instance = payload[1]
            const accounts = await payload[0].request({ method: 'eth_accounts' })            

            state.mainAddress = accounts[0]
            state.mainAddressShort = accounts[0].substring(0, 5) + '....' + accounts[0].substring(accounts[0].length-5)
            state.Web3.connected = true
            state.Web3.instance.eth.getBalance(accounts[0], (err, balance) => {
              state.ethBallance = balance
              state.ethBallanceNice = state.Web3.instance.utils.fromWei(balance, "ether").substring(0, 5) + " ETH"
            });
        },
        clearUpconnectionMutation(state) {
            state.Web3.instance = null
            state.Web3.connected = false
            state.mainAddress = "unknown"
            state.mainAddressShort = "unknown"
        },
        writepaymentMasterContractAddressMutation(state, payload) {
            state.gameContractAddress = payload
        },
        logShootMutation(state, payload) {
            // Check duplicates
            if (!(state.stakesHistory.filter(e => e.Shoothash === payload.Shoothash).length > 0)) {
                // add to stakesHistory
                state.stakesHistory.unshift({
                    'Shoothash':        payload.Shoothash,
                    'wallet':           payload.wallet,
                    'walletNice':       payload.wallet.substring(0, 5) + '....' + payload.wallet.substring(payload.wallet.length-5), 
                    'gameNumber':       payload.gameNumber, 
                    'stakeNumber':      payload.stakeNumber, 
                    'ShootValue':       (payload.ShootValue / 10**18).toString().substring(0, 6),
                    'WinHash':          '',
                    'winValue':         ''
                })
            }
            let round_counter = 0
            let index = 0
            let last_round = -1
            for (;index < state.stakesHistory.length; index++) {
                if (last_round.toString() != state.stakesHistory[index].gameNumber) {
                    last_round = parseInt(state.stakesHistory[index].gameNumber)
                    round_counter++;
                }
                if (round_counter > HISTORY_GAMES_COUNT) {
                    break;
                }
            }

            // show only last HISTORY_GAMES_COUNT rounds
            if (index > 0) {
                state.stakesHistory.splice(index);
            }
            writeWins()
        },
        writeWinsToHistoryMutation(state, ) {
            // write wins with removing it            
            let last_round = -1;
            state.winHistory.forEach(function (winelem, index, object) {
                state.stakesHistory.forEach(element => {
                    if ((element.gameNumber == winelem.gameNumber) && (element.stakeNumber == winelem.stakeNumber)) {
                        element.WinHash     =   winelem.WinHash
                        element.winValue    =   (winelem.winValue / 10**18).toString().substring(0, 6)
                        
                        object.splice(index, 1);
                        last_round = parseInt(winelem.gameNumber)
                    }
                })
            })            
            state.winHistory.forEach(function (winelem, index, object) {
                if (winelem > last_round) {
                    object.splice(index, 1); 
                }                
            });

        },
        logWinMutation(state, payload) {
            // add to stakesHistory
            state.winHistory.unshift({
                'WinHash' : payload.WinHash, 
                'gameNumber' : payload.gameNumber, 
                'stakeNumber' : payload.stakeNumber, 
                'winValue' : payload.winValue
            })

            writeWins();
        }
    },
    actions: {
        registerWeb3 ({commit}) {
            GetWeb3.then(async result => {
                if (result[0]) {
                  let accounts = await result[1].request({ method: 'eth_accounts' })
                  
                  if (accounts.length > 0) {
                      commit('registerWeb3Instance', [result[1], result[2]])
                  }
                }                
            }).catch(e => {
                console.log('registerWeb3Instance action error=', e)
            })
        },
        clearUpconnection({commit}) {
            commit('clearUpconnectionMutation')
        },
        writepaymentMasterContractAddress({commit}, payload){
            commit('writepaymentMasterContractAddressMutation', payload)
        },
        logShoot({commit}, payload){
            commit('logShootMutation', payload)
        },
        logWin({commit}, payload){
            commit('logWinMutation', payload)
        },
        writeWinsToHistory({commit}, payload){
            commit('writeWinsToHistoryMutation', payload)
        }
    }
})